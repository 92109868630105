import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import LayoutPaginas from "../../components/LayoutPaginas";
import { Navbar } from "../../components/Navbar";
import Section from "../../components/Section";
import SectionBannersFooter from "../../components/Sections/SectionBannersFooter";
import { api, apiVercel } from "../../services/aixos";
import SwipperEncarte from "./swipper/swipperEncarte";


export default function Encarte() {
  const [loading, setLoading] = useState(false);
  const [encarte, setEncarte] = useState([{
    id: 0,
    titulo: '',
    imagens: {
      primeira_folha: '',
      segunda_folha: '',
      terceira_folha: '',
      quarta_folha: '',
      quinta_folha: '',
    }
  }])

  async function getEncarte() {
    setLoading(true)
    await new Promise((res) => setTimeout(res, 700))

    const response = await apiVercel.get('/api/economico/encarte')
    setEncarte(response.data.encarte)

    setLoading(false)
  }

  useEffect(() => {
    getEncarte()
  }, [])

  return (
    <LayoutPaginas>
      <Section>
        <div className="bg-brand-red text-white border-t-1 border-white p-5 md:text-left text-center">
          <Container>
            <ul className="inline-flex gap-2">
              <li className="after:content-['-']"> Você está em </li> 
              <li className="after:content-['-'] after:ml-2"> 
                <a href="/" className="hover:cursor-pointer hover:underline hover:underline-offset-2">
                  Home 
                </a>
              </li>
              <li > 
                Encarte
              </li>
            </ul>
            <h1 className="font-bold text-4xl"> Encarte </h1>
          </Container>
        </div>
        <Container>
        { 
          encarte.map(encarte => (
            <>
            <div className="mt-16 mb-16 ">
              <h2 className="md:text-start text-center">{encarte.titulo}</h2>
              <p className="md:text-start text-center">Confira os produtos em destaque do econômico</p>
              </div>
              <div className="container-lojas flex justify-center">
                <div className="mb-16 gap-4 grid grid-cols-1 ">
                  <SwipperEncarte {...encarte.imagens}/>
                </div>
            </div>
            </>
          ))
        }
        </Container>
        <SectionBannersFooter />
        <Footer/>
      </Section>    
    </LayoutPaginas>

  );
}