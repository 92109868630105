import React, { useEffect, useState } from 'react'
import { IoLogoWhatsapp } from 'react-icons/io';
import { Link } from 'react-router-dom';
import SwiperHero from './SwiperHero';
import SocialIcons from './SocialIcons';
import { Navbar, NavbarTemporary } from './Navbar';
import { api, apiVercel } from '../services/aixos';

function HeaderPaginas() {
    const [loading, setLoading] = useState(false);
    const [atendimento, setSocial] = useState([{
      id: 1,
      whatsapp: '',
    }])
  
    async function getAtendimento() {
      setLoading(true)
  
      const response = await apiVercel.get('/api/economico/atendimento')
      setSocial(response.data.atendimento)
  
      setLoading(false)
    }
  
    useEffect(() => {
      getAtendimento()
    }, [])
    
    return (
        <header className="bg-brand-red text-white bg-[url('../../public/img/bg-header.webp')] bg-cover bg-no-repeat">
            <div className=" items-center container mx-auto px-12">
                <div className='py-2 flex justify-end'>
                    <div className='flex items-center gap-2 mr-2'>
                        <IoLogoWhatsapp size={22} />
                        {
                            atendimento.map((atendimento, index) => (
                                <a key={index}  href={`https://api.whatsapp.com/send?phone=55${atendimento.whatsapp}`} target={'_blank'}>
                                    Atendimento Whatsapp
                                    <span className='font-bold mr-2'>
                                    {` (${atendimento.whatsapp.slice(0,2)}) ${atendimento.whatsapp.slice(2,7)}-${atendimento.whatsapp.slice(7)}`}
                                    </span>
                                </a>
                            ))
                        }
                    </div>
                    <SocialIcons />
                </div>
                <Navbar/>

            </div>
        </header>
    )
}

export default HeaderPaginas