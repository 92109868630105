import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

export function Navbar() {
  function scrollToFooter() {
    // window.scroll(0, 1700)
    window.scrollTo({
      top: 1500,
      behavior: 'smooth'
    })
    console.log('scroll')
  }

  return (
    <div className="navbar">
      {/* MENU MOBILE */}
      <div className="navbar-container">
        <div className="dropdown flex justify-between">
          <label tabIndex={0} className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </label>
          <ul
            tabIndex={0}
            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-brand-red rounded-box w-52"
          >
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/nossa-historia">Institucional</Link>
            </li>
            {/* <li>
              <Link to="/encarte">Encarte</Link>
            </li> */}
            <li>
              <Link to="/lojas">Lojas</Link>
            </li>
            {/* <li>
              <a className="justify-between">
                Lojas
                <svg
                  className="fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                </svg>
              </a>
              <ul className="z-10 bg-white">
                <li>
                  <a
                    href="/lojas/detalhes"
                    className="pl-12 pr-12 font-bold text-black hover:bg-[#FFEFEF] hover:text-brand-red"
                  >
                    Coqueiro
                  </a>
                </li>
                <li>
                  <a
                    href="/lojas/detalhes"
                    className="pl-12 pr-12 font-bold text-black hover:bg-[#FFEFEF] hover:text-brand-red"
                  >
                    Jurunas
                  </a>
                </li>
                <li>
                  <a
                    href="/lojas/detalhes"
                    className="pl-12 pr-12 font-bold text-black hover:bg-[#FFEFEF] hover:text-brand-red"
                  >
                    Pedreira
                  </a>
                </li>
                <li>
                  <a
                    href="/lojas/detalhes"
                    className="pl-12 pr-12 font-bold text-black hover:bg-[#FFEFEF] hover:text-brand-red"
                  >
                    Umarizal
                  </a>
                </li>
                <li>
                  <a
                    href="/lojas/detalhes"
                    className="pl-12 pr-12 font-bold text-black hover:bg-[#FFEFEF] hover:text-brand-red"
                  >
                    Castanheira
                  </a>
                </li>
              </ul>
            </li> */}
            {/* <li>
              <Link to="/ofertas">Ofertas</Link>
            </li> */}
            {/* <li><Link to="/">Notícias</Link></li> */}
            <li>
              <Link to="/cartao">
                Cartão Econômico
              </Link>
            </li>
            <li>
              <Link to="/" onClick={scrollToFooter}>
                Atendimento
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <Link to="/">
        <div className="lg:absolute md:absolute  lg:left-24 xl:left-36 md:left-[70%] lg:pt-6 md:pt-16 md:pb-2 ">
          <img
            src="/img/logo.png"
            alt="economico alimentos"
            width="153"
            height="139"
            className="-translate-y-6 pt-2 w-[100px]"
          />
        </div>
      </Link>
      {/* MENU WEB */}
      <div className="navbar-end hidden lg:flex w-[100%]">
        <ul className="menu menu-horizontal p-0 gap-8">
          <li className="pr-8 border-r-2">
            <Link
              to="/"
              className="p-0 font-bold hover:bg-transparent hover:text-red-200"
            >
              Home
            </Link>
          </li>
          <li className="pr-8 border-r-2">
            <Link
              to="/nossa-historia"
              className="p-0 font-bold hover:bg-transparent hover:text-red-200"
            >
              Institucional
            </Link>
          </li>
          {/* <li className="pr-8 border-r-2">
            <Link
              to="/encarte"
              className="p-0 font-bold hover:bg-transparent hover:text-red-200"
            >
              Encarte
            </Link>
          </li> */}
          <li tabIndex={0} className="pr-8 border-r-2">
            <a
              href="/lojas"
              className="p-0 font-bold hover:bg-transparent hover:text-red-200"
            >
              Lojas
              <svg
                className="fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
              </svg>
            </a>
            <ul className="p-4 z-10 bg-white">
              <li>
                <Link
                  to="/lojas/detalhes/unidade-jurunas"
                  className="pl-8 pr-8 font-bold text-black hover:bg-[#FFEFEF] hover:text-brand-red"
                >
                  Jurunas
                </Link>
              </li>
              <li>
                <Link
                  to="/lojas/detalhes/unidade-umarizal"
                  className="pl-8 pr-8 font-bold text-black hover:bg-[#FFEFEF] hover:text-brand-red"
                >
                  Umarizal
                </Link>
              </li>
              <li>
                <Link
                  to="/lojas/detalhes/unidade-pedreira"
                  className="pl-8 pr-8 font-bold text-black hover:bg-[#FFEFEF] hover:text-brand-red"
                >
                  Pedreira
                </Link>
              </li>
              <li>
                <Link
                  to="/lojas/detalhes/unidade-coqueiro"
                  className="pl-8 pr-8 font-bold text-black hover:bg-[#FFEFEF] hover:text-brand-red"
                >
                  Mário covas
                </Link>
              </li>
              <li>
                <Link
                  to="/lojas/detalhes/unidade-castanheira"
                  className="pl-8 pr-8 font-bold text-black hover:bg-[#FFEFEF] hover:text-brand-red"
                >
                  Entrocamento
                </Link>
              </li>
            </ul>
          </li>
          {/* <li className="pr-8 border-r-2">
            <Link
              to="/ofertas"
              className="p-0 font-bold hover:bg-transparent hover:text-red-200"
            >
              Ofertas
            </Link>
          </li> */}
          {/* <li className='pr-8 border-r-2'><Link to="/noticias" className='p-0 font-bold hover:bg-transparent hover:text-red-200'>Notícias</Link></li> */}
          <li className="pr-8 border-r-2">
            <Link
              to="/cartao"
              className="p-0 font-bold hover:bg-transparent hover:text-red-200 mx-auto"
            >
              Cartão Econômico
            </Link>
          </li>
          <li>
            <Link
              to="/"
              className="p-0 font-bold hover:bg-transparent hover:text-red-200"
              onClick={scrollToFooter}
            >
              Atendimento
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
export function NavbarTemporary() {
  const [isMenuFixed, setIsMenuFixed] = useState(false)

  function changeBg() {
    if (window.scrollY > 10) {
      setIsMenuFixed(true)
    } else {
      setIsMenuFixed(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeBg)
  }, [])

  return (
    <div className={`${isMenuFixed ? 'fixed-navbar' : ''} navbar-temporary`}>
      <img src="/img/logo.png" alt="economico alimentos" />
    </div>
  )
}
