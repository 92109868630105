import HeaderPaginas from './HeaderPaginas'

function LayoutPaginas({ children }) {
    return (
        <>
            <HeaderPaginas />
            {children}
        </>
    )
}

export default LayoutPaginas