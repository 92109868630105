import Container from '../../components/Container'
import Footer from '../../components/Footer'
import LayoutPaginas from '../../components/LayoutPaginas'
import Section from '../../components/Section'
import Duvida from '../../components/Duvida'
import SectionBannersFooter from '../../components/Sections/SectionBannersFooter'

export default function Cartao() {

  return (
    <>
      <LayoutPaginas>
        <Section>
          <div className="bg-brand-red text-white border-t-1 border-white p-5 md:text-left text-center">
            <Container>
              <ul className="inline-flex gap-2">
                <li className="after:content-['-']"> Você está em </li>
                <li className="after:content-['-'] after:ml-2">
                  <a
                    href="/"
                    className="hover:cursor-pointer hover:underline hover:underline-offset-2"
                  >
                    Home
                  </a>
                </li>
                <li> Cartão Econômico</li>
              </ul>
              <h1 className="font-bold text-4xl"> Cartão Econômico </h1>
            </Container>
          </div>
          <section>
            <div className='w-[90%] mx-auto'>
              <div className='mb-28 mt-8 lg:mt-24  lg:flex'>
                <div className='lg:w-[50%] px-8 sm:px-0 lg:pr-16 xl:pr-6'>
                  <h2 className='text-5xl text-zinc-900'>Chegou a hora de Economizar!</h2>
                  <p className='text-zinc-400 mt-6 text-justify'> Finalmente chegou o nosso cartão. Veja como é facil tirar o seu. Basta você apresentar os seus documentos abaixo <strong>(Original e Cópia)</strong> em qualquer econômico:</p>
                  <ul className='text-zinc-400 flex flex-col gap-2.5 my-6 list-disc marker:text-[#EB3237] ml-[3.5%]'>
                    <li>RG;</li>
                    <li>CPF;</li>
                    <li>Comprovante de residência do mês vigente;</li>
                    <li>Celular em mãos;</li>
                  </ul>
                  <p className='font-black text-zinc-900'>Caso de aprovação imediata, o cliente já sai comprando na hora!</p>
                  <a href="/Lojas"><div className='bg-[#EB3237] mx-auto sm:ml-0 py-5 my-6 rounded-full w-[90%] lg:w-[50%] h-[60px] align-text-bottom'>
                    <h2 className='text-sm text-[#fff] text-center'>Encontre um Econômico</h2>
                  </div>
                  </a>
                </div>
                <div className='w-[230px] h-[230px] mx-auto lg:mr-0 bg-[#EB3237] rounded-full xl:scale-[255%] lg:max-xl:scale-[220%] lg:max-[1100%]:translate-x-[-40%] lg:translate-y-[45%] lg:translate-x-[-45%] xl:translate-x-[-60%]'>
                  <img src="./img/img-cartao.png" className='translate-y-[20%] translate-x-[-12%]' alt="cartão" />
                  <img src="./img/img-modelo.png" className='translate-y-[10%] translate-x-[-30%] lg:max-[1100px]:translate-x-[-18%] xl:scale-[85%] xl:translate-y-[1%] xl:translate-x-[-25%]' alt="modelo" />
                </div>
              </div>
              <div className='mb-24 mt-[340px] lg:mt-0 px-4'>
                <h2 className='text-5xl text-zinc-900'>Vantagens e benefícios</h2>
                <ul className='text-zinc-400 flex flex-col gap-2.5 my-6 list-disc marker:text-[#EB3237] ml-[1.75%] pl-4'>
                  <li>Parcele suas compras em até 3x sem juros.</li>
                  <li>Participe de sorteios semanais de carrinhos em compras.</li>
                </ul>
                <p className='font-black text-zinc-900 mb-6'>E ainda tem mais...</p>
                <div className='md:flex md:gap-3 xl:gap-10 lg:max-[1050px]:translate-x-[-2%]'>
                  <div className='flex gap-3 mb-3 xl:gap-10 justify-center'>
                    <div className='border w-[160px] h-[160px] lg:py-[8%] py-[6%] rounded-xl lg:p-1'>
                      <div>
                        <img src="./img/icons/icon-indique.svg" className='mx-auto my-[3%]' alt="" />
                        <p className='font-bold text-zinc-900 text-center'>Indique e ganhe</p>
                      </div>
                    </div>
                    <div className='border w-[160px] h-[160px] lg:py-[8%] py-[6%] rounded-xl lg:p-0'>
                      <div className='p-2'>
                        <img src="./img/icons/icon-ganhe.svg" className='mx-auto my-[3%]' alt="" />
                        <p className='font-bold text-zinc-900 text-center'>Ganhe Cashback</p>
                      </div>
                    </div>
                  </div>
                  <div className='flex gap-3 xl:gap-10 justify-center'>
                    <div className='border w-[160px] h-[160px] lg:py-[8%] py-[6%] rounded-xl lg:p-0'>
                      <div className='p-2'>
                        <img src="./img/icons/icon-limites.svg" className='mx-auto my-[3%]' alt="" />
                        <p className='font-bold text-zinc-900 text-center'>Limites Especiais</p>
                      </div>
                    </div>
                    <div className='border w-[160px] h-[160px] lg:py-[8%] py-[6%] rounded-xl lg:p-0'>
                      <div className='p-2'>
                        <img src="./img/icons/icon-cartoes.svg" className='mx-auto my-[6%]' alt="" />
                        <p className='font-bold text-zinc-900 text-center'>Cartões Extras</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className='bg-[#EB3237] mb-6'>
            <Container>
              <div className='py-24 '>
                <h2 className='text-[#fff] mb-5'>Dúvidas Frequentes</h2>
                <div className='lg:flex md:justify-between w-[100%]'>
                  <div className='flex flex-col gap-6 w-[100%] '>
                    <Duvida titulo="Onde eu posso solicitar?" resposta="Para pedir o cartão Econômico, basta ir até uma das nossas lojas. Veja os benefícios, procure a loja de sua preferência e solicite uma proposta. É só preencher e aguardar a análise de crédito. Se o crédito for aprovado, você já poderá sair comprando na hora. Peça o seu cartão!" />
                    <Duvida titulo="Como funciona o parcelamento?" resposta="Você pode parcelar suas compras no cartão Econômico em até 3x sem juros. Só o cartão Econômico oferece esse benefício e comodidade. Venha até uma das nossas lojas e peça o seu!" />
                  </div>
                  <div className='flex flex-col gap-6 w-[100%]'>
                    <Duvida titulo="Meu cartão foi roubado ou perdido, e agora?" resposta="Não se preocupe! Se aconteceu essa fatalidade, basta se dirigir a alguma de nossas lojas ou entrar em contato com nossa central, que iremos orientar quais o procedimento para bloqueio do cartão e solicitação de um novinho." />
                    <Duvida titulo="Onde eu posso usar meu cartão Econômico?" resposta="O seu cartão Econômico pode ser utilizado em qualquer uma das nossas lojas! Basta escolher o que deseja comprar e na hora que estiver passando no caixa apresentar seu cartão e um documento de identificação." />
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <SectionBannersFooter />
          <Footer />
        </Section>
      </LayoutPaginas>
    </>
  )
}