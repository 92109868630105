import React from 'react'

const divStyle = {
  backgroundColor: '#EB3237',
  borderRadius: '50%',
  boxShadow: '0px 0px 2px 16px #fff'
}

export default function LinhaDoTempo() {
  return (
    <div className="flex flex-col justify-center py-6 lg:py-12">
      <div className="w-full mx-auto lg:max-w-4xl">
        <div className="relative">
          <div className="absolute hidden w-1 h-full transform -translate-x-1/2 bg-brand-red lg:block left-1/2"></div>
          <div className="space-y-12 lg:space-y-8">
            {/* 1 - LADO DIREITO */}
            <div>
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-end w-full mx-auto">
                  <div className="w-full lg:w-1/2 lg:pl-14">
                    <div className="p-4 bg-white rounded lg:mt-0 lg:text-left md:mt-16 md:text-center mt-16 text-center">
                      <h2 className="text-2xl text-brand-red">1991</h2>
                      <p>
                        A empresa nasceu no ano de 1991 como um balcão de
                        atacado no bairro do Entroncamento, em Belém. Fundada
                        pelos atuais presidentes, Yasnaia e Celso Carlos.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={divStyle}
                  className="absolute flex items-center justify-center w-16 h-16 transform -translate-x-1/2 -translate-y-4 bg-brand-red rounded-full left-1/2 sm:translate-y-0"
                >
                  <span className="text-white p-2">
                    <img src="/img/nossa-historia/e.svg" alt="" />
                  </span>
                </div>
              </div>
            </div>

            {/* 2 - LADO ESQUERDO */}
            <div>
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-start w-full mx-auto">
                  <div className="w-full lg:w-1/2 lg:pr-14">
                    <div className="p-4 bg-white rounded lg:mt-0 lg:text-end md:mt-16 md:text-center mt-16 text-center">
                      <h2 className="text-2xl text-brand-red">2004</h2>
                      <p>
                        Passou por várias mudanças, se tornando uma pequena loja
                        e expandindo a cada ano.
                      </p>
                      <img
                        className="mx-auto mb-4 mt-4"
                        src="/img/nossa-historia/logo-antiga.jpg"
                        alt="Logo Antiga do Econômico"
                      />
                      <p>
                        Em 2004, a operação mudou de nome, foi de balcão de
                        atacado para uma atuação híbrida conhecida regionalmente
                        como “Meio a Meio” e que se aproximava cada vez mais do
                        método varejista.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={divStyle}
                  className="absolute flex items-center justify-center w-16 h-16 transform -translate-x-1/2 -translate-y-4 bg-brand-red rounded-full  left-1/2 sm:translate-y-0"
                >
                  <span className="text-white p-2">
                    <img src="/img/nossa-historia/change.svg" alt="" />
                  </span>
                </div>
              </div>
            </div>

            {/* 3 - LADO DIREITO */}
            <div>
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-end w-full mx-auto">
                  <div className="w-full lg:w-1/2 lg:pl-14">
                    <div className="p-4 bg-white rounded lg:mt-0 lg:text-left md:mt-16 md:text-center mt-16 text-center">
                      <h2 className="text-2xl text-brand-red">2014</h2>
                      <p>
                        Com o passar dos anos, a atuação passou a ser
                        majoritariamente varejista. E no ano de 2014, foi aberta
                        a segunda unidade na rua Mário Covas, na cidade de
                        Ananindeua.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={divStyle}
                  className="absolute flex items-center justify-center w-16 h-16 transform -translate-x-1/2 -translate-y-4 bg-brand-red rounded-full left-1/2 sm:translate-y-0"
                >
                  <span className="text-white p-2">
                    <img src="/img/nossa-historia/shop.svg" alt="" />
                  </span>
                </div>
              </div>
            </div>

            {/* 4 - LADO ESQUERDO */}
            <div>
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-start w-full mx-auto">
                  <div className="w-full lg:w-1/2 lg:pr-14">
                    <div className="p-4 bg-white rounded lg:mt-0 lg:text-end md:mt-16 md:text-center mt-16 text-center">
                      <h2 className="text-2xl text-brand-red">2017</h2>
                      <p>
                        A partir de 2017 e da abertura da loja da Pedreira, a
                        empresa passou a apresentar crescimento mais arrojado
                        para o mercado local.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={divStyle}
                  className="absolute flex items-center justify-center w-16 h-16 transform -translate-x-1/2 -translate-y-4 bg-brand-red rounded-full  left-1/2 sm:translate-y-0"
                >
                  <span className="text-white p-2">
                    <img src="/img/nossa-historia/shop.svg" alt="" />
                  </span>
                </div>
              </div>
            </div>

            {/* 5 - LADO DIREITO */}
            <div>
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-end w-full mx-auto">
                  <div className="w-full lg:w-1/2 lg:pl-14">
                    <div className="p-4 bg-white rounded lg:mt-0 lg:text-left md:mt-16 md:text-center mt-16 text-center">
                      <h2 className="text-2xl text-brand-red">2019</h2>
                      <p>
                        Em 2019, inaugurou mais uma filial no bairro do Jurunas
                        e conseguiu expandir seu sucesso.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={divStyle}
                  className="absolute flex items-center justify-center w-16 h-16 transform -translate-x-1/2 -translate-y-4 bg-brand-red rounded-full left-1/2 sm:translate-y-0"
                >
                  <span className="text-white p-2">
                    <img src="/img/nossa-historia/shop.svg" alt="" />
                  </span>
                </div>
              </div>
            </div>

            {/* 6 - LADO ESQUERDO */}
            <div>
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-start w-full mx-auto">
                  <div className="w-full lg:w-1/2 lg:pr-14">
                    <div className="p-4 bg-white rounded lg:mt-0 lg:text-end md:mt-16 md:text-center mt-16 text-center">
                      <h2 className="text-2xl text-brand-red">2020</h2>
                      <p>
                        No ano de 2020, abriram sua loja conceito na região mais
                        valorizada da cidade: Umarizal. Ambiente inovador e
                        moderno, unindo tradição e tecnologia à veia de
                        Atacarejo da empresa. Mais um sucesso que caiu no gosto
                        dos clientes Econômico.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={divStyle}
                  className="absolute flex items-center justify-center w-16 h-16 transform -translate-x-1/2 -translate-y-4 bg-brand-red rounded-full  left-1/2 sm:translate-y-0"
                >
                  <span className="text-white p-2">
                    <img src="/img/nossa-historia/shop2.svg" alt="" />
                  </span>
                </div>
              </div>
            </div>

            {/* 7 - LADO DIREITO */}
            <div>
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-end w-full mx-auto">
                  <div className="w-full lg:w-1/2 lg:pl-14">
                    <div className="p-4 bg-white rounded lg:mt-0 lg:text-left md:mt-16 md:text-center mt-16 text-center">
                      <h2 className="text-2xl text-brand-red">2021</h2>

                      {/* <img
                        className=" bg-zinc-500 rounded"
                        src="/img/faturamento-2.png"
                        alt=""
                      /> */}
                      <p className='mt-2'>
                        E no ritmo de crescimento, a quantidade de cupons
                        evoluiu 192% no mesmo período. O faturamento de 2021
                        representou um crescimento de 289% sobre 2017 (últimos 5
                        anos).
                      </p>

                      <div className="grid grid-row-2 mt-4">
                        <img
                          className=" bg-zinc-500 rounded  "
                          src="/img/faturamento-1.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={divStyle}
                  className="absolute flex items-center justify-center w-16 h-16 transform -translate-x-1/2 -translate-y-4 bg-brand-red rounded-full left-1/2 sm:translate-y-0"
                >
                  <span className="text-white p-2">
                    <img src="/img/nossa-historia/growth.svg" alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
