
export function FooterUnidades(props) {

    return (
        <div className='my-4'>
            <h4 className='text-red-200 font-bold'>{props.nome}</h4>
            <div className='grid grid-cols-8 mt-2'>
                <div className='col-span-1 pt-2'>
                    <img src="/img/ping.png" alt="ping" width="20" height="27" />
                </div>
                <div className='col-span-7'>
                    {props.endereco}
                </div>
            </div>
            <div className='grid grid-cols-8 mt-4'>
                <div className='col-span-1 pt-2'>
                    <img src="/img/ballon.png" alt="ping" width="20" height="20" />
                </div>
                <div className='col-span-7'>
                    {props.contatos.map(contato => (
                        <div key={contato.id}>
                            {contato.contato}
                        </div>
                    ))}
                </div>
            </div>
            <div className='grid grid-cols-8 mt-4'>
                <div className='col-span-1 pt-2'>
                    <img src="/img/clock.png" alt="ping" width="22" height="22" />
                </div>
                <div className='col-span-7'>
                    {props.horario_funcionamento_resumo.map(item => (
                        <div key={item.id}>
                            {item.descricao}
                        </div>
                    ))}
                </div>
            </div>


        </div>
    )
}

export function FooterUnidadesPlaceholder() {
    return (
        <div className='my-4'>
            <h4 className='text-red-200 font-bold'><div className="w-32 h-4 animate-pulse bg-red-800 bg-opacity-75 rounded-md"></div></h4>
            <div className='grid grid-cols-8 mt-2'>
                <div className='col-span-1 pt-2'>
                    <img src="/img/ping.png" alt="ping" width="20" height="27" />
                </div>
                <div className='col-span-7'>
                    <div className="w-42 h-8 animate-pulse bg-red-800 bg-opacity-75 rounded-md"></div>
                </div>
            </div>
            <div className='grid grid-cols-8 mt-4'>
                <div className='col-span-1 pt-2'>
                    <img src="/img/ballon.png" alt="ping" width="20" height="20" />
                </div>
                <div className='col-span-7'>
                    <div className="w-42 h-8 animate-pulse bg-red-800 bg-opacity-75 rounded-md my-2"></div>

                </div>
            </div>
            <div className='grid grid-cols-8 mt-4'>
                <div className='col-span-1 pt-2'>
                    <img src="/img/clock.png" alt="ping" width="22" height="22" />
                </div>
                <div className='col-span-7'>
                    <div className="w-42 h-8 animate-pulse bg-red-800 bg-opacity-75 rounded-md my-2"></div>

                </div>
            </div>
        </div>
    )
}
