import { FaFacebook } from 'react-icons/fa'
import { AiFillInstagram, AiFillLinkedin } from 'react-icons/ai'
import { api, apiVercel } from '../services/aixos'
import { useEffect, useState } from 'react'

function SocialIcons({ type = '' }) {
  const [loading, setLoading] = useState(false)
  const [atendimento, setSocial] = useState([
    {
      id: 1,
      link_facebook: '',
      link_instagram: '',
      link_youtube: '',
      link_linkedin: ''
    }
  ])

  async function getAtendimento() {
    setLoading(true)

    const response = await apiVercel.get('/api/economico/atendimento')
    setSocial(response.data.atendimento)

    setLoading(false)
  }

  useEffect(() => {
    getAtendimento()
  }, [])
  return (
    <div className="flex gap-3  items-center text-white">
      {atendimento.map((atendimento, index) => (
        <div className="flex gap-2" key={index}>
          <a
            href={atendimento.link_facebook}
            target="_blank"
            rel="noreferrer"
            className="mr-1 pt-[2px]"
          >
            {/* {type === "black" ? ():()} */}

            {type === 'black' ? (
              <FaFacebook size={25} className="text-zinc-400 mt-4 pt-1 " />
            ) : (
              <FaFacebook size={18} />
            )}
          </a>
          <a href={atendimento.link_instagram} target="_blank" rel="noreferrer">
            {type === 'black' ? (
              <AiFillInstagram size={30} className="text-zinc-400 mt-4" />
            ) : (
              <AiFillInstagram size={22} />
            )}
          </a>

          <a href={atendimento.link_linkedin} target="_blank" rel="noreferrer">
            {type === 'black' ? (
              <AiFillLinkedin size={30} className="text-zinc-400 mt-4" />
            ) : (
              <AiFillLinkedin size={22} />
            )}
          </a>
          {/* <FaBeer /> */}
        </div>
      ))}
    </div>
  )
}

export default SocialIcons
