import { Link } from 'react-router-dom'
import Breadcrumb from '../../components/Breadcrumb'
import Container from '../../components/Container'
import Footer from '../../components/Footer'
import LayoutPaginas from '../../components/LayoutPaginas'
import Section from '../../components/Section'
import SectionBannersFooter from '../../components/Sections/SectionBannersFooter'
import LinhaDoTempo from './components/LinhaDoTempo'
import MissaoVisaoValores from './components/MissaoVisaoValores'
import Premiacoes from './components/Premiaçoes'

export default function NossaHistoria() {
  const MissaoVisaoValoresData = {
    titulo: 'Missão',
    descricao: 'Gerar satisfação no cliente, através de um atendimento rápido, com qualidade e o melhor preço.',
    img: '/img/icons/missao.svg'
  }
  const MissaoVisaoValoresDataSegundo = {
    titulo: 'Visão',
    descricao: 'Ser referência no estilo de atuação na região norte.',
    img: '/img/icons/visao.svg'
  }
  const MissaoVisaoValoresDataTerceiro = {
    titulo: 'Valores',
    descricao: 'Honestidade, ética, respeito, transparência, responsabilidade social, sustentabilidade.',
    img: '/img/icons/valores.svg'
  }

  const Premiacoesdata = {
    titulo: 'Premiaçoes',
    img: 'img/trophy.svg'
  }

  return (
    <LayoutPaginas>
      <Section>
        <div className="bg-brand-red text-white border-t-1 border-white p-5 md:text-left text-center">
          <Container>
            <ul className="inline-flex gap-2">
              <li className="after:content-['-']"> Você está em </li>
              <li className="after:content-['-'] after:ml-2">
                <a
                  href="/"
                  className="hover:cursor-pointer hover:underline hover:underline-offset-2"
                >
                  Home
                </a>
              </li>
              <li> Institucional </li>
            </ul>
            <h1 className="font-bold text-4xl"> Nossa História </h1>
          </Container>
        </div>
        <Container>
          <div className="grid gap-2 mt-16 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
            <div className="mx-auto md:py-[20%] lg:py-[5%] xl:py-0">
              <img
                className="rounded-2xl"
                src="/img/temporary/economico.jpg"
                alt=""
              />
            </div>
            <div className='xl:py-6'>
              <MissaoVisaoValores {...MissaoVisaoValoresData} />
              <MissaoVisaoValores {...MissaoVisaoValoresDataSegundo} />
              <MissaoVisaoValores {...MissaoVisaoValoresDataTerceiro} />
            </div>
          </div>
          <div className="grid grid-col-1 mt-16 text-center">
            <h2>A linha do tempo do Grupo Econômico</h2>
            <p className="mt-3">A nossa trajetória de sucesso</p>
          </div>
          <LinhaDoTempo />
          <Premiacoes {...Premiacoesdata} />
        </Container>
        <SectionBannersFooter />
        <Footer />
      </Section>
    </LayoutPaginas>
  )
}
