import React, { useEffect, useState } from 'react'
// import Swiper core and required modules
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Banner } from './Banner';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './style.css'
import { banners } from '../../services/banners-db'; 
import { api, apiVercel } from '../../services/aixos';

export default function SwiperHero() {
    const [loading, setLoading] = useState(false);
    const [banner, setEncarte] = useState([]);
  
    async function getEncarte() {
      setLoading(true)
      await new Promise((res) => setTimeout(res, 700))
  
      const response = await apiVercel.get('/api/economico/banners-home')
      setEncarte(response.data.banners)
  
      setLoading(false)
    }
  
    useEffect(() => {
      getEncarte()
    }, [])

    return (
        <div className='container mx-auto px-2'>
            <Swiper
                pagination={{
                    dynamicBullets: true,
                }}
                  autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
                modules={[Pagination, Autoplay]}
                className="swiper-hero"
            >
                {banners.map(banner => (
                    <SwiperSlide key={banner.id}>
                        <Banner image={banner.image} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>

    )
}

