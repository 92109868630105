/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react';
import './style.scss';
// import { Link } from 'react-router-dom'
import Breadcrumb from '../../components/Breadcrumb';
import Container from '../../components/Container';
import Footer from '../../components/Footer';
import Section from '../../components/Section';
import SectionBannersFooter from '../../components/Sections/SectionBannersFooter';
import { api } from '../../services/aixos';
import { SwiperLojas } from '../../components/SwiperLojas';
import LayoutPaginas from '../../components/LayoutPaginas';
import { Link, useParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export default function DetalhesLojas() {
  let params = useParams();

  const [loading, setLoading] = useState(false);

  const [loja, setLoja] = useState(undefined);

  async function getCrescente() {
    setLoading(true);

    const response = await api.get(
      `api/economico/lojas/detalhes/${params.slug}`
    );

    setLoja(response.data.lojadetalhes);
    setLoading(false);
    console.log(response.data.lojadetalhes);
  }

  useEffect(() => {
    getCrescente();
  }, [params]);

  useEffect(() => {
    getCrescente();
  }, []);

  return (
    <LayoutPaginas>
      <Section>
        <div className="bg-brand-red text-white border-t-1 border-white p-5 md:text-left text-center">
          <Container>
            <ul className="inline-flex gap-2">
              <li className="after:content-['-']"> Você está em </li>
              <li className="after:content-['-'] after:ml-2">
                <a
                  href="/"
                  className="hover:cursor-pointer hover:underline hover:underline-offset-2"
                >
                  Home
                </a>
              </li>
              <li className="after:content-['-'] after:ml-2">
                <a
                  href="/lojas"
                  className="hover:cursor-pointer hover:underline hover:underline-offset-2"
                >
                  Lojas
                </a>
              </li>
              <li>{loja?.nome}</li>
            </ul>
            <h1 className="font-bold text-4xl"> {loja?.nome} </h1>
          </Container>
        </div>
        <Container>
          <div className="mt-16 mb-16 ">
            <h2 className="md:text-start text-center">
              Conheça a {loja?.nome}
            </h2>
            <p className="md:text-start text-center">
              Tem sempre um econômico perto de você
            </p>
          </div>
          <div className="mb-16 md:gap-12 sm:gap-0 gap-0 sm:grid block xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 ">
            <div>
              {params.slug == 'unidade-jurunas' && (
                <div>
                  <img
                    className="rounded-2xl"
                    src="./img/fachadas/jurunas.jpg"
                  />
                  <div className="flex justify-center">
                    <HashLink to="/galeria#jurunas">
                      <div className="bg-white py-4 px-6 my-6 rounded-full border-[2px] border-[#EB3237]">
                        <span className="text-lg text-[#EB3237] text-center font-bold">
                          Galeria de imagens
                        </span>
                      </div>
                    </HashLink>
                  </div>
                </div>
              )}
              {params.slug == 'unidade-castanheira' && (
                <div>
                  <img
                    className="rounded-2xl"
                    src="./img/fachadas/entroncamento.jpg"
                  />
                  <div className="flex justify-center">
                    <HashLink to="/galeria#entrocamento">
                      <div className="bg-white py-4 px-6 my-6 rounded-full border-[2px] border-[#EB3237]">
                        <span className="text-lg text-[#EB3237] text-center font-bold">
                          Galeria de imagens
                        </span>
                      </div>
                    </HashLink>
                  </div>
                </div>
              )}
              {params.slug == 'unidade-coqueiro' && (
                <div>
                  <img
                    className="rounded-2xl"
                    src="./img/fachadas/coqueiro.jpg"
                  />
                  <div className="flex justify-center">
                    <HashLink to="/galeria#marioCovas">
                      <div className="bg-white py-4 px-6 my-6 rounded-full border-[2px] border-[#EB3237]">
                        <span className="text-lg text-[#EB3237] text-center font-bold">
                          Galeria de imagens
                        </span>
                      </div>
                    </HashLink>
                  </div>
                </div>
              )}
              {params.slug == 'unidade-umarizal' && (
                <div>
                  <img
                    className="rounded-2xl"
                    src="./img/fachadas/umarizal.jpg"
                  />
                  <div className="flex justify-center">
                    <HashLink to="/galeria#umarizal">
                      <div className="bg-white py-4 px-6 my-6 rounded-full border-[2px] border-[#EB3237]">
                        <span className="text-lg text-[#EB3237] text-center font-bold">
                          Galeria de imagens
                        </span>
                      </div>
                    </HashLink>
                  </div>
                </div>
              )}
              {params.slug == 'unidade-pedreira' && (
                <div>
                  <img
                    className="rounded-2xl"
                    src="./img/fachadas/pedreira.jpg"
                  />

                  <div className="flex justify-center">
                    <HashLink smooth to="/galeria#pedreira">
                      <div className="bg-white py-4 px-6 my-6 rounded-full border-[2px] border-[#EB3237]">
                        <span className="text-lg text-[#EB3237] text-center font-bold">
                          Galeria de imagens
                        </span>
                      </div>
                    </HashLink>
                  </div>
                </div>
              )}
            </div>
            <div className="col-span-2 sm:mt-4 mt-20">
              <h2> {loja?.nome} </h2>
              <p> {loja?.descricao_unidade} </p>

              <div className="grid lg:grid-cols-3 md:grid-cols-1 grid-cols-1 mt-4 gap-4">
                <div>
                  <h2 className="text-lg">Endereço</h2>
                  <p>{loja?.endereco}</p>
                </div>
                <div>
                  <h2 className="text-lg">Horário de Atendimento!</h2>
                  {loja &&
                    loja.horario_atendimento.map((item) => (
                      <p>
                        {' '}
                        <strong>{item.dia_semana}</strong>
                        {':'}
                        <span className="pl-2">{item.horario}</span>
                      </p>
                    ))}
                </div>
                <div>
                  <h2 className="text-lg">Telefones</h2>
                  {loja &&
                    loja.telefones_atendimento.map((item) => (
                      <p>
                        {' '}
                        <strong>{item.tipo}</strong>
                        {':'}
                        <span className="pl-2">{item.telefone}</span>
                      </p>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </Container>
        <SectionBannersFooter />
        <Footer />
      </Section>
    </LayoutPaginas>
  );
}
