function SectionBannersFooter(props) {
  return (
    <section>
      <div className="grid md:grid-cols-2 gap-2">
        <a
          href="/nossa-historia"
          className="hover:scale-105 transition duration-300 ease-in-out"
        >
          <img
            src="/img/bannerfooter/banner1.webp"
            className="h-auto w-full"
            alt="Seja Econômico"
            width="960"
            height="328"
          />
        </a>
        
        <a
          href="/galeria"
          className="hover:scale-105 transition duration-300 ease-in-out"
        >
          <img
            src="/img/bannerfooter/banner2.webp"
            className="h-auto w-full"
            alt="Conheça o Econômico"
            width="960"
            height="328"
          />
        </a>

        {/* <div style={{ backgroundImage: `url(${props.primeiro_img_banner})` }} className="grid grid-cols-2 px-2 md:px-12 pt-4">
                    <div className="text-center">
                        <img src="/img/bg-footer-2.png" alt="seja economico sempre!" data-aos="fade" />
                        <button className="ring-1 ring-white text-white md:px-8 md:py-3 rounded-full mt-2 md:mt-6 text-sm p-2">Conheça o Econômico</button>
                    </div>
                    <img src="/img/familia.png" alt="familia" data-aos="fade" />
                </div>
                <div className="bg-[url('/public/img/bg-footer-1-fundo.png')] grid grid-cols-2 px-2 md:px-12 pt-4">
                    <div className="text-center">
                        <img src="/img/bg-footer-1.png" alt="seja economico sempre!" data-aos="fade" />
                        <button className="ring-1 ring-white text-white md:px-8 md:py-3 rounded-full mt-2 md:mt-6 text-sm p-2 mb-2">Ver Galeria</button>
                    </div>
                </div> */}
      </div>
    </section>
  )
}

export default SectionBannersFooter
