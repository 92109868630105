import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SocialIcons from './SocialIcons'
import { FooterUnidades, FooterUnidadesPlaceholder } from './FooterUnidades'
import { api, apiVercel } from '../services/aixos'
import { useFetch } from '../services/useFetch'

export default function Footer() {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const { data } = useFetch('/api/economico/lojas')

  return (
    <footer className="bg-brand-blood py-24 mt-4" id="footer">
      <div className="container-xl mx-auto px-2">
        <div className="grid md:grid-cols-4 sm:grid-cols-3">
          <div className="col-span-1">
            <Link to="/" onClick={scrollToTop}>
              <img
                className="md:pl-10  md:w-60 mb-8 pl-4"
                src="/img/logo.png"
                alt="economico alimentos"
                width="153"
                height="139"
              />
            </Link>
          </div>
          <div className="col-span-3">
            <h3 className="text-white font-bold text-xl mr-2">Unidades</h3>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 text-white gap-10">
              {data &&
                data.lojas.map((unidade, index) => (
                  <FooterUnidades key={index} {...unidade} />
                ))}
            </div>

            <div className="w-64 flex items-center mt-8">
              <h3 className="text-white font-bold text-xl mr-2">
                Redes Sociais{' '}
              </h3>
              <SocialIcons />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
