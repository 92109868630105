import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Breadcrumb from '../../components/Breadcrumb'
import Container from '../../components/Container'
import Footer from '../../components/Footer'
// import Input from '../../components/Form/Input'
import Section from '../../components/Section'
import SectionBannersFooter from '../../components/Sections/SectionBannersFooter'
import SocialIcons from '../../components/SocialIcons'
import { api, apiVercel } from '../../services/aixos'
import { FcOk } from 'react-icons/fc'
import LayoutPaginas from '../../components/LayoutPaginas'
import MaskedInput from 'react-text-mask'

export default function Atendimento() {
  const [unidades, setUnidades] = useState([])

  async function getUnidades() {
    const response = await apiVercel.get('/api/economico/unidades')
    setUnidades(response.data.unidades)

    console.log('unidades', response.data.unidades)
  }

  useEffect(() => {
    getUnidades()
  }, [])

  useEffect(() => {
    console.log('unidades', unidades)
  }, [unidades])

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful }
  } = useForm()
  const onSubmit = async data => {
    console.log(data, isSubmitSuccessful)
  }

  return (
    <>
      <LayoutPaginas>
        <Section>
          <div className="bg-brand-red text-white border-t-1 border-white p-5 md:text-left text-center">
            <Container>
              <ul className="inline-flex gap-2">
                <li className="after:content-['-']"> Você está em </li> 
                <li className="after:content-['-'] after:ml-2"> 
                  <a href="/" className="hover:cursor-pointer hover:underline hover:underline-offset-2">
                    Home 
                  </a>
                </li>
                <li > 
                  Atendimento
                </li>
              </ul>
              <h1 className="font-bold text-4xl"> Atendimento  </h1>
            </Container>
          </div>
          <Container>
            <div className="mt-16 mb-16 ">
              <h2 className="md:text-start text-center">Atendimento</h2>
              <p className="md:text-start text-center mb-20">
                Mande sua mensagem para gente...
              </p>

              <div className="md:grid md:grid-cols-2 d-flex grid-cols-1">
                <form
                  className="mb-10 md:text-start text-center"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <input
                    placeholder="Nome"
                    type="text"
                    name="Nome"
                    {...register('nome', { required: true })}
                    className="input-style"
                  />

                  {/* <input
                    placeholder="Telefone"
                    type="Number"
                    name="Telefone"
                    {...register('telefone', { required: true })}
                    className="input-style"
                  /> */}
                  <MaskedInput
                    className="input-style"
                    placeholder="Telefone"
                    name="Telefone"
                    mask={[
                      '(',
                      /\d/,
                      /\d/,
                      ')',
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/
                    ]}
                    {...register('telefone', { required: true })}
                  />

                  <input
                    placeholder="Email"
                    type="E-mail"
                    name="Email"
                    {...register('email', { required: true })}
                    className="input-style"
                  />
                  <input
                    placeholder="Assunto"
                    type="text"
                    name="Assunto"
                    {...register('assunto', { required: true })}
                    className="input-style"
                  />

                  <textarea
                    name="Mensagem"
                    id="Mensagem"
                    placeholder="Mensagem"
                    maxLength="400"
                    className="w-5/6 h-60 border-gray border-[2px]
                  pl-4 pt-2 focus:outline-gray-400 text-gray-600 resize-none"
                    {...register('mensagem', { required: true })}
                  ></textarea>

                  {errors.nome && (
                    <p className="text-orange-300 font-semibold">
                      Favor preencher o Nome
                    </p>
                  )}

                  {errors.telefone && (
                    <p className="text-orange-300 font-semibold">
                      Favor preencher o Telefone
                    </p>
                  )}

                  {errors.email && (
                    <p className="text-orange-300 font-semibold">
                      Favor preencher o E-mail
                    </p>
                  )}

                  {errors.assunto && (
                    <p className="text-orange-300 font-semibold">
                      Favor preencher o Assunto
                    </p>
                  )}

                  {errors.mensagem && (
                    <p className="text-orange-300 font-semibold">
                      Favor preencher o Mensagem
                    </p>
                  )}

                  {isSubmitSuccessful === true ? (
                    <p className="text-green-400 flex gap-2 font-semibold">
                      {' '}
                      Enviado com Sucesso!{' '}
                      <span className="pt-[5px]">
                        <FcOk />
                      </span>
                    </p>
                  ) : (
                    ''
                  )}
                  <div className="md:text-start">
                    <button className="bg-brand-red text-white py-3 px-4 rounded-full hover:bg-red-600 transition-all shadow mt-10">
                      Enviar mensagem
                    </button>
                  </div>
                </form>
                <div className=" grid grid-cols-1 md:grid-cols-2 md:max-h-[280px] md:max-w-md">
                  {unidades.map((item, index) => {
                    return (
                      <div key={`${item.id}-${index}`}>
                        <p className=" font-bold text-brand-red pt-4">
                          {item.nome}
                        </p>

                        {item.contatos.map((contato, index1) => {
                          return (
                            <p
                              key={`${contato.id}-${index1}`}
                              className="text-black"
                            >
                              {contato.contato}
                            </p>
                          )
                        })}
                      </div>
                    )
                  })}

                  <div className="flex flex-col mb-10 md:w-[300px]">
                    <h3 className="text-brand-red font-bold text-xl pt-10">
                      Redes Sociais
                    </h3>
                    <SocialIcons className="text-black" type="black" />
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <SectionBannersFooter />
          <Footer />
        </Section>
      </LayoutPaginas>
    </>
  )
}
