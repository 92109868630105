import React, { useEffect, useState } from 'react'
import Subtitle from '../Subtitle'
import { CardLoja, CardLojaPlaceholder } from './CardLoja'
import { MdFingerprint } from 'react-icons/md'
import Container from '../Container'
import { api } from '../../services/aixos'

function SectionLojas() {
  const [loading, setLoading] = useState(false)
  const [unidade, setUnidade] = useState([])
  const [unidadeCurrent, setUnidadeCurrent] = useState(undefined)

  async function getUnidade(id) {
    setLoading(true)

    const response = await api.get(`api/economico/lojas/${id}`)

    setUnidadeCurrent(response.data.loja)
    console.log(response.data.loja)

    setLoading(false)
  }

  // o update acontece no carregamento do componente e não na execução de uma função

  useEffect(() => {
    getUnidade(1)
  }, [])

  useEffect(() => {
    setUnidadeCurrent(unidade)
  }, [unidade])

  return (
    <section className="bg-brand-zinc-100 py-16">
      <Container>
        <Subtitle>Lojas</Subtitle>
        <div className="mt-5">
          <div className="dropdown mt-2 border md:hidden mb-4 mx-auto">
            <label
              tabIndex={0}
              className="px-12 py-3 border shadow text-sm font-bold bg-white cursor-pointer"
            >
              Selecionar Supermercado{' '}
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content menu p-2 shadow bg-base-100  w-52 rounded-none"
            >
              <button
                onClick={() => getUnidade(1)}
                className="py-4 shadow my-1 font-bold flex items-center  justify-center gap-2"
              >
                Jurunas <img className="w-8" src="/img/ifoodLogo.png" alt="#" />
              </button>
              <button
                onClick={() => getUnidade(2)}
                className="py-4 shadow my-1 font-bold flex items-center  justify-center gap-2"
              >
                Umarizal{' '}
                <img className="w-8" src="/img/ifoodLogo.png" alt="#" />
              </button>
              <button
                onClick={() => getUnidade(3)}
                className="py-4 shadow my-1 font-bold"
              >
                Pedreira
              </button>
              <button
                onClick={() => getUnidade(4)}
                className="py-4 shadow my-1 font-bold"
              >
                Mário covas
              </button>
              <button
                onClick={() => getUnidade(5)}
                className="py-4 shadow my-1 font-bold"
              >
                Entrocamento
              </button>
            </ul>
          </div>
        </div>
        <div className="grid md:grid-cols-3 mt-6">
          <div className="col-span-2">
            {loading ? (
              <CardLojaPlaceholder />
            ) : (
              unidadeCurrent && <CardLoja unidadeCurrent={unidadeCurrent} />
            )}
          </div>
          <div className="hidden col-span-1 md:grid gap-4">
            <button
              onClick={() => getUnidade(1)}
              className="btn-section-lojas flex items-center  justify-center gap-2"
            >
              {' '}
              Jurunas <img
                className="w-8"
                src="/img/ifoodLogo.png"
                alt="#"
              />{' '}
            </button>
            <button
              onClick={() => getUnidade(2)}
              className="btn-section-lojas flex items-center  justify-center gap-2"
            >
              Umarizal
              <img className="w-8" src="/img/ifoodLogo.png" alt="#" />
            </button>
            <button onClick={() => getUnidade(3)} className="btn-section-lojas">
              Pedreira
            </button>
            <button onClick={() => getUnidade(4)} className="btn-section-lojas">
              Mário Covas
            </button>
            <button onClick={() => getUnidade(5)} className="btn-section-lojas">
              Entroncamento
            </button>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default SectionLojas
