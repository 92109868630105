import { useState } from 'react'

export function CardLoja({ unidadeCurrent }) {
  const [linkIfood, setLinkIfood] = useState([])

  return (
    <div
      className="grid md:grid-cols-1 gap-4 lg:grid-cols-1 xl:grid-cols-5"
      data-aos="fade"
    >
      <div
        className="max-w-[370px] min-w-[310px]  aspect-[1/1] bg-cover bg-center col-span-2"
        style={{ backgroundImage: `url(${unidadeCurrent.capa})` }}
      ></div>

      <div className="col-span-3">
        <h3 className="text-4xl text-brand-200 text-brand-dark font-extrabold">
          {unidadeCurrent.nome}
        </h3>

        {/* <p className="mt-5">{unidadeCurrent.descricao}</p> */}
        <h3 className="text-brand-dark font-extrabold mt-8">Endereço</h3>
        <p>{unidadeCurrent.endereco}</p>

        {/* 
        <div className="dropdown mt-2 border">
          <label
            tabIndex={0}
            className="px-12 py-3 border shadow text-sm font-bold bg-white cursor-pointer "
          >
            Ver semana
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content menu p-2 shadow  w-52 rounded-none bg-white "
          >
            {unidadeCurrent.horario_funcionamento.map(item => (
              <li key={item.id} className="text-brand-zinc-200 mt-2  text-sm">
                {item.descricao}
              </li>
            ))}
          </ul>
        </div> */}

        <div className=" mt-2 list-none grid lg:grid-cols-2">
          <div>
            <h3 className="text-brand-dark font-extrabold mt-8">
              Horário de atendimento
            </h3>

            <ul>
              {unidadeCurrent.horario_funcionamento_resumo.map(item => (
                <li key={item.id} className="text-brand-zinc-200 mt-2  text-sm">
                  {item.descricao}
                </li>
              ))}
            </ul>

            <div>
              {unidadeCurrent.link_food ? (
                <div className="mt-8">
                  <a
                    href={unidadeCurrent.link_food}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="font-extrabold"> Peça pelo </span>
                    <img src="/img/ifoodLogo.png" alt="" width={80} />
                  </a>
                </div>
              ) : (
                ''
              )}{' '}
            </div>
          </div>

          <div>
            {' '}
            <div className="mt-8">
              {unidadeCurrent.aberto ? (
                <p className="text-green-400">
                  {' '}
                  <img
                    className="md:pl-10  md:w-60 mb-8 lg:mt-9 md:pr-4  w-64 hover:translate-y-1"
                    src="/img/card-aberto.webp"
                    alt="aberto"
                    width="566"
                    height="441"
                  />
                </p>
              ) : (
                <p className="text-brand-red">
                  <img
                    className="md:pl-10  md:w-60 mb-8 lg:mt-9 md:pr-4  w-64 hover:translate-y-1"
                    src="/img/card-fechago.webp"
                    alt="fechado"
                    width="566"
                    height="441"
                  />
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function CardLojaPlaceholder() {
  return (
    <div className="grid md:grid-cols-1 gap-4 lg:grid-cols-1 xl:grid-cols-5">
      <div className="max-w-[370px]  min-w-[310px] aspect-[1/1] bg-cover bg-center animate-pulse bg-slate-200 col-span-2"></div>
      <div className="col-span-3 px-4">
        <div className="w-full h-4 animate-pulse bg-slate-200 bg-opacity-75 rounded-md mt-5"></div>
        <div className="w-full mt-4 h-16 animate-pulse bg-slate-200 bg-opacity-75 rounded-md"></div>
        <div className="w-full mt-4 h-16 animate-pulse bg-slate-200 bg-opacity-75 rounded-md"></div>
        <div className="w-full mt-4 h-16 animate-pulse bg-slate-200 bg-opacity-75 rounded-md"></div>
      </div>
    </div>
  )
}
