import Container from '../../components/Container';
import Footer from '../../components/Footer';
import LayoutPaginas from '../../components/LayoutPaginas';
import Section from '../../components/Section';
import { SwiperGaleria } from './swipper/SwiperGaleria';
import { SwiperUnidade } from './swipper/SwiperUnidade';

export default function Galeria() {
  return (
    <>
      <LayoutPaginas>
        <Section>
          <div className="bg-brand-red text-white border-t-1 border-white p-5 md:text-left text-center">
            <Container>
              <ul className="inline-flex gap-2">
                <li className="after:content-['-']"> Você está em </li>
                <li className="after:content-['-'] after:ml-2">
                  <a
                    href="/"
                    className="hover:cursor-pointer hover:underline hover:underline-offset-2"
                  >
                    Home
                  </a>
                </li>
                <li> Galeria de imagens</li>
              </ul>
              <h1 className="font-bold text-4xl"> Galeria de Imagens </h1>
            </Container>
          </div>
          <Container>
            <div className="mt-4">
              <h2>Institucional</h2>
            </div>
            <div className="mx-auto h-auto mb-10 ">
              <SwiperUnidade url="api/economico/galeria/institucional" />
            </div>
            <div className="mt-[100px]" id="pedreira">
              <h2>Unidade Pedreira</h2>
            </div>
            <div className="mx-auto h-auto mb-10 ">
              <SwiperUnidade url="api/economico/galeria/unidade-pedreira" />
            </div>
            <div className="mt-[100px]" id="umarizal">
              <h2>Unidade Umarizal</h2>
            </div>
            <div className="mx-auto h-auto mb-10 ">
              <SwiperUnidade url="api/economico/galeria/unidade-umarizal" />
            </div>
            <div className="mt-[100px]" id="marioCovas">
              <h2>Unidade Mário Covas</h2>
            </div>
            <div className="mx-auto h-auto mb-10 ">
              <SwiperUnidade url="api/economico/galeria/unidade-mario-covas" />
            </div>
            <div className="mt-[100px]" id="entrocamento">
              <h2>Unidade Entroncamento</h2>
            </div>
            <div className="mx-auto h-auto mb-10 ">
              <SwiperUnidade url="api/economico/galeria/unidade-entroncamento" />
            </div>
            <div className="mt-[100px]" id="jurunas">
              <h2>Unidade Jurunas</h2>
            </div>
            <div className="mx-auto h-auto mb-10">
              <SwiperUnidade url="api/economico/galeria/unidade-jurunas" />
            </div>
          </Container>

          <Footer />
        </Section>
      </LayoutPaginas>
    </>
  );
}
