import { useState } from 'react'

export default function Duvida(props) {
    const [show, setShow] = useState(false)

    function handleClick() {
        if (show === true) {
            setShow(false)
        } else {
            setShow(true)
        }
    }

    return (
        <div>
            <div className='mx-auto border-b w-[90%] pb-3 h-[100px] flex justify-between hover:cursor-pointer hover:bg-red-400 py-8' onClick={handleClick}>
                <p className='text-sm text-[#fff] text-[18px] font-black w-[90%]'>
                    {props.titulo}
                </p>
                {!show
                ?
                <img src="./img/seta.png" className='w-3 h-2' alt="setaIcon" />
                :
                <img className='rotate-180 w-3 h-2' src="./img/seta.png" alt="" />
                }
            </div>
            {
                show && <p className='text-[#fff] text-xl font-thin mt-3 text-justify px-[15%]'>{props.resposta}</p>
            }
        </div>
    )
}