import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import './swiperStyle.css';

// import required modules
import { FreeMode, Navigation } from 'swiper';
import { api, apiSitebeta, apiVercel } from '../../../services/aixos';

export function SwiperUnidade(props) {
  const [galeria, setGaleria] = useState([]);

  async function getGaleria() {
    try {
      const response = await apiSitebeta.get(props.url);
      setGaleria(response?.data.galeria);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getGaleria();
  }, []);

  return (
    <Swiper
      loop={true}
      navigation={true}
      modules={[FreeMode, Navigation]}
      breakpoints={{
        1024: {
          slidesPerView: 2,
        },
        1300: {
          slidesPerView: 3,
        },
      }}
    >
      {galeria &&
        galeria.map((imagem) => (
          <SwiperSlide key={imagem.id} pagination>
            <img
              src={imagem.url}
              className="mx-auto max-w-full h-[200px] rounded shadow"
            />
          </SwiperSlide>
        ))}
    </Swiper>
  );
}
