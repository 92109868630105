import React, { useRef, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import '../swipper/style.scss'

// import required modules
import { Pagination, Navigation } from 'swiper'

export default function SwipperEncarte(props) {
  return (
    <>
      <Swiper
        pagination={{
          type: 'fraction',
        }}
        navigation={true}
        loop={true}
        modules={[Pagination, Navigation]}
        className="swipperEncarte"
      >
        <SwiperSlide>
          <img src={props.primeira_folha} alt="" className="max-w-xl" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={props.segunda_folha} alt="" className="max-w-xl" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={props.terceira_folha} alt="" className="max-w-xl" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={props.quarta_folha} alt="" className="max-w-xl" />
        </SwiperSlide>
      </Swiper>
    </>
  )
}
