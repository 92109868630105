export function CardNoticia() {

    function handleGoToNoticia() {
        console.log('vai para a noticia')
    }

    return (
        <div onClick={handleGoToNoticia} className="group first:w-[320px] aspect-[1/1] border bg-cover relative p-4 flex flex-col justify-end cursor-pointer" style={{ backgroundImage: `url(/img/temporary/noticia-1.png)` }} >

            <div className="z-40 text-start group-hover:translate-y-[200px] transition-all">

                <span className="px-3 py-1 border rounded-full text-white z-40 text-[14px]">
                    10/06/2022
                </span>

                <p className="text-white font-bold z-30 text-sm text-start mb-6 mt-2">
                    Novidades no Horti da unidade Umarizal
                </p>

            </div>

            <span className="absolute top-0 right-0 w-[100%] h-[100%] bg-gradient-to-b from-transparent to-black z-10 group-hover:to-transparent transition-all"></span>

            <img src="/img/chevron-right.svg" alt="right" className='absolute z-20 bottom-5 right-5 group-hover:translate-x-2 transition-all' />

        </div>

    )
}
