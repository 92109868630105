export const banners = [{
    id: 1,
    title: 'segunda-feira',
    image: '/img/temporary/segunda.webp'
},
// {
//     id: 2,
//     title: 'terca-feira',
//     image: '/img/temporary/terca.png'
// },
{
    id: 3,
    title: 'quarta-feira',
    image: '/img/temporary/quarta.webp'
},
{
    id: 4,
    title: 'quinta-feira',
    image: '/img/temporary/quinta.webp'
},
    // {
    //     id: 5,
    //     title: 'sexta-feira',
    //     image: '/img/temporary/sexta.png'
    // },
    // {
    //     id: 6,
    //     title: 'sabado',
    //     image: '/img/temporary/sabado.png'
    // },
    // {
    //     id: 7,
    //     title: 'domingo',
    //     image: '/img/temporary/domingo.png'
    // }


]