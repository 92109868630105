import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import LayoutPaginas from "../../components/LayoutPaginas";
import Section from "../../components/Section";
import SectionBannersFooter from "../../components/Sections/SectionBannersFooter";
import { CardNoticia } from "../../components/SwiperNoticias/CardNoticia";



export default function Noticias() {
    return (
      <>
      <LayoutPaginas>
        <Section>
          <div className="bg-brand-red text-white border-t-1 border-white p-5 md:text-left text-center">
            <Container>
              <ul className="inline-flex gap-2">
                <li className="after:content-['-']"> Você está em </li> 
                <li className="after:content-['-'] after:ml-2"> 
                  <a href="/" className="hover:cursor-pointer hover:underline hover:underline-offset-2">
                    Home 
                  </a>
                </li>
                <li > 
                  Notícias
                </li>
              </ul>
              <h1 className="font-bold text-4xl"> Notícias  </h1>
            </Container>
          </div>
          <Container>
            <div className="mt-16 mb-16 ">
              <h2 className="md:text-start text-center">Novidades no horti da unidade Umarizal</h2>
              <p className="md:text-start text-center">Publicado em 10/06/2022</p>
            </div>
            <div className="mb-16 gap-4 grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 ">
              <CardNoticia/>
            </div>
          </Container>
          <SectionBannersFooter />
          <Footer/>
        </Section>
      </LayoutPaginas>
      </>
    );
}