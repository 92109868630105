import './style.css'

type MissaoVisaoValoresProps = {
  titulo: string
  descricao: string
  img: string
}

export default function MissaoVisaoValores({
  titulo,
  descricao,
  img
}: MissaoVisaoValoresProps) {
  return (
    <div className="grid grid-cols-[100px_minmax(200px,_1fr)] mt-4">
      <div className="img-icone rounded-full bg-brand-red mx-auto">
        <img className="p-4" src={img} alt="" />
      </div>
      <div className='lg:min-w-[80%] lg:ml-1'>
        <h2 className="text-2xl text-brand-red">{titulo}</h2>
        <p className="text-sm">{descricao}</p>
      </div>
    </div>
  )
}
