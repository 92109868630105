import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Atendimento from './pages/Atendimento'
import Home from './pages/Home'
import Lojas from './pages/Lojas/lista'
import DetalhesLojas from './pages/Lojas/detalhes'
import NossaHistoria from './pages/NossaHistoria'
import Ofertas from './pages/Ofertas'
import Encarte from './pages/Encarte'
import Noticias from './pages/Noticias'
import Galeria from './pages/galeria'
import CartaoEconomico from './pages/CartaoEconomico'

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nossa-historia" element={<NossaHistoria />} />
        <Route path="/lojas" element={<Lojas />} />
        <Route path="/lojas/detalhes/:slug" element={<DetalhesLojas />} />
        <Route path="/ofertas" element={<Ofertas />} />
        <Route path="/encarte" element={<Encarte />} />
        <Route path="/noticias" element={<Noticias />} />
        <Route path="/atendimento" element={<Atendimento />} />
        <Route path="/galeria" element={<Galeria />} />
        <Route path="/Cartao" element={<CartaoEconomico />}/>

      </Routes>
    </BrowserRouter>
  )
}
