interface PremiacoesProps {
  titulo: string
  img: string
}

export default function Premiacoes({ titulo, img }: PremiacoesProps) {
  return (
    <div className="grid lg:grid-cols-[100px_minmax(200px,_1fr)] mt-4 items-center mb-10">
      <div className="img-icone rounded-full bg-brand-red mx-auto relative lg:bottom-24 xl:bottom-14">
        <img src={img} alt="" />
      </div>
      <div>
        <h2 className="text-center mt-2 md:text-center lg:text-left">{titulo}</h2>
        <div className="grid lg:grid-cols-2">
          {' '}
          <div>
            <p>
              O Grupo Econômico tem orgulho em destacar algumas premiações que
              foram conquistadas durante e após a pandemia, mesmo com diversos
              entraves, conseguimos ser destaque!
            </p>
            <p className="font-bold mt-2">
              1º lugar Ranking Atacado de Balcão 2022 - ABAD/NILSEN <br />
              8º lugar Ranking Maiores Empresas da Região Norte 2022 -
              ABAD/NILSEN <br />
              Melhor Atacadista Distribuidor Estadual 2021 – ABAD/TTL
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
