import { Link, NavLink } from 'react-router-dom'
import Container from '../../components/Container'
import Footer from '../../components/Footer'
import LayoutPaginas from '../../components/LayoutPaginas'
import Section from '../../components/Section'
import SectionBannersFooter from '../../components/Sections/SectionBannersFooter'
import './style.scss'
import { useFetch, useFetchByContrato } from '../../services/useFetch'

export default function Lojas() {
  const { data } = useFetchByContrato('/lojas/lista/lojascrescente')

  return (
    <>
      <LayoutPaginas>
        <Section>
          <div className="bg-brand-red text-white border-t-1 border-white p-5 md:text-left text-center">
            <Container>
              <ul className="inline-flex gap-2">
                <li className="after:content-['-']"> Você está em </li>
                <li className="after:content-['-'] after:ml-2">
                  <a
                    href="/"
                    className="hover:cursor-pointer hover:underline hover:underline-offset-2"
                  >
                    Home
                  </a>
                </li>
                <li> Lojas </li>
              </ul>
              <h1 className="font-bold text-4xl"> Nossas Unidades </h1>
            </Container>
          </div>
          <Container>
            <div className="mt-16 mb-16 ">
              <h2 className="md:text-start text-center">
                Conheça nossas unidades
              </h2>
              <p className="md:text-start text-center">
                Escolha o econômico mais perto de você
              </p>
            </div>
            <div className="container-lojas flex justify-center">
              <div className="mb-16 gap-4 grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 ">
                {data &&
                  data.lojascrescente.map(loja => (
                    <Link
                      to={`/lojas/detalhes/${loja.slug}`}
                      className="scale-100 hover:scale-105 transition duration-300 ease-in-out"
                    >
                      <div className="relative max-w-xs">
                        <img src={loja.img_fachada} alt={loja.nome} />
                        <div className="tituloLoja absolute text-white h-16 border-b-2 border-red-600">
                          <h2 className="text-xl w-20 mx-auto">{loja.nome}</h2>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </Container>
          <SectionBannersFooter />
          <Footer />
        </Section>
      </LayoutPaginas>
    </>
  )
}
