import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb from '../../components/Breadcrumb'
import Container from '../../components/Container'
import Footer from '../../components/Footer'
import LayoutPaginas from '../../components/LayoutPaginas'
import Section from '../../components/Section'
import SectionBannersFooter from '../../components/Sections/SectionBannersFooter'
import { api, apiVercel } from '../../services/aixos'

export default function Ofertas() {
  const [loading, setLoading] = useState(false)
  const [ofertas, setOfertasCrescente] = useState([
    {
      id: 0,
      nome: '',
      link_oferta: '',
      img_oferta: ''
    }
  ])

  async function getOfertasCrescente() {
    setLoading(true)

    const response = await api.get('/api/economico/ofertas')
    setOfertasCrescente(response.data.ofertas)

    setLoading(false)
  }

  useEffect(() => {
    getOfertasCrescente()
  }, [])

  return (
    <LayoutPaginas>
      <Section>
        <div className="bg-brand-red text-white border-t-1 border-white p-5 md:text-left text-center">
          <Container>
            <ul className="inline-flex gap-2">
              <li className="after:content-['-']"> Você está em </li> 
              <li className="after:content-['-'] after:ml-2"> 
                <a href="/" className="hover:cursor-pointer hover:underline hover:underline-offset-2">
                  Home 
                </a>
              </li>
              <li > 
                Ofertas
              </li>
            </ul>
            <h1 className="font-bold text-4xl"> Ofertas  </h1>
          </Container>
        </div>
        <Container>
          <div className="mt-16 mb-16 ">
            <h2 className="md:text-start text-center">
              Confira as ofertas que estão rolando...
            </h2>
            <p className="md:text-start text-center">
              Tem sempre uma oferta certa para você
            </p>
          </div>
          <div className="container-lojas flex justify-center">
            <div className="mb-16 gap-4 grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 ">
              {ofertas.map(oferta => (
                <a
                  href={oferta.link_oferta}
                  className="scale-100 hover:scale-105 transition duration-300 ease-in-out"
                >
                  <div className="relative max-w-xs">
                    <img src={oferta.img_oferta} alt={oferta.nome} />
                  </div>
                </a>
              ))}
            </div>
          </div>
        </Container>
        <SectionBannersFooter />
        <Footer />
      </Section>
    </LayoutPaginas>
  )
}
