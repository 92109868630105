import axios from 'axios'

export const apiVercel = axios.create({
  baseURL: 'https://api-temp.vercel.app/',
});

export const apiSitebeta = axios.create({
  baseURL: 'https://economicoapi.sitebeta.com.br/',
});

export const api = axios.create({
  baseURL: 'https://economicoapi.sitebeta.com.br/',
});
