import Layout from '../../components/Layout'
import SectionBannersFooter from '../../components/Sections/SectionBannersFooter'
import SectionLojas from '../../components/Sections/SectionLojas'

export default function Home() {
  return (
    <Layout>
      {/* <SectionEncarteDigital /> */}
      <SectionLojas />
      {/* <SectionOfertas /> */}
      {/* <SectionNoticias /> */}
      <SectionBannersFooter />
    </Layout>
  )
}
